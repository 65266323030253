/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { GenericResultBean } from '../model/genericResultBean';
import { RequestTrasferimentoGiornalieroBean } from '../model/requestTrasferimentoGiornalieroBean';
import { ResultBrtNumSpedBean } from '../model/resultBrtNumSpedBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { InputStreamResource } from '../model/inputStreamResource';


@Injectable()
export class BrtControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * confermaSpedizioniBrtMagazzino
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confermaSpedizioniBrtMagazzinoUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public confermaSpedizioniBrtMagazzinoUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public confermaSpedizioniBrtMagazzinoUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public confermaSpedizioniBrtMagazzinoUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling confermaSpedizioniBrtMagazzinoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/confermaSpedizioniBrtMagazzino`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confermaSpedizioniBrtProduzione
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confermaSpedizioniBrtProduzioneUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public confermaSpedizioniBrtProduzioneUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public confermaSpedizioniBrtProduzioneUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public confermaSpedizioniBrtProduzioneUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling confermaSpedizioniBrtProduzioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/confermaSpedizioniBrtProduzione`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confermaSpedizioniBrt
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confermaSpedizioniBrtUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public confermaSpedizioniBrtUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public confermaSpedizioniBrtUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public confermaSpedizioniBrtUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling confermaSpedizioniBrtUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/confermaSpedizioniBrt`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * eliminaSpediziniMultiple
     * 
     * @param idOrdini idOrdini
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eliminaSpediziniMultipleUsingPOST(idOrdini: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public eliminaSpediziniMultipleUsingPOST(idOrdini: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public eliminaSpediziniMultipleUsingPOST(idOrdini: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public eliminaSpediziniMultipleUsingPOST(idOrdini: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idOrdini === null || idOrdini === undefined) {
            throw new Error('Required parameter idOrdini was null or undefined when calling eliminaSpediziniMultipleUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/eliminaSpediziniMultiple`,
            idOrdini,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAndSetTracking
     * 
     * @param corriere corriere
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAndSetTrackingUsingPOST(corriere: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public getAndSetTrackingUsingPOST(corriere: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public getAndSetTrackingUsingPOST(corriere: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public getAndSetTrackingUsingPOST(corriere: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (corriere === null || corriere === undefined) {
            throw new Error('Required parameter corriere was null or undefined when calling getAndSetTrackingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/getAndSetTracking`,
            corriere,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTrackingByIdOrder
     * 
     * @param idOrder idOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackingByIdOrderUsingPOST(idOrder: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public getTrackingByIdOrderUsingPOST(idOrder: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public getTrackingByIdOrderUsingPOST(idOrder: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public getTrackingByIdOrderUsingPOST(idOrder: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idOrder === null || idOrder === undefined) {
            throw new Error('Required parameter idOrder was null or undefined when calling getTrackingByIdOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/api/brt/getTrackingByIdOrder`,
            idOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTrackingDpdByIdCollo
     * 
     * @param idCollo idCollo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackingDpdByIdColloUsingPOST(idCollo: number, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public getTrackingDpdByIdColloUsingPOST(idCollo: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public getTrackingDpdByIdColloUsingPOST(idCollo: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public getTrackingDpdByIdColloUsingPOST(idCollo: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCollo === null || idCollo === undefined) {
            throw new Error('Required parameter idCollo was null or undefined when calling getTrackingDpdByIdColloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/getTrackingDpdByIdCollo`,
            idCollo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBrtNumSped
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getbrtNumSpedUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResultBrtNumSpedBean>;
    public getbrtNumSpedUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultBrtNumSpedBean>>;
    public getbrtNumSpedUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultBrtNumSpedBean>>;
    public getbrtNumSpedUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResultBrtNumSpedBean>(`${this.basePath}/api/brt/getbrtNumSped`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * testCreateSped
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testCreateSpedUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public testCreateSpedUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public testCreateSpedUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public testCreateSpedUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/testCreateSped`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * testDeleteSped
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testDeleteSpedUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public testDeleteSpedUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public testDeleteSpedUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public testDeleteSpedUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/brt/testDeleteSped`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * testMail
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testMailUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public testMailUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public testMailUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public testMailUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/brt/testMail`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Genera report xls consegnati
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public generaReportXlsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
        public generaReportXlsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
        public generaReportXlsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
        public generaReportXlsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/pdf'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.get(`${this.basePath}/api/brt/generaReportXlsBrt`, { responseType: 'blob' });

        }

}
